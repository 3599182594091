<template>
  <div>
    <!--    <header-slot></header-slot>-->
    <b-nav
      card-header
      pills
      class="m-0"
    >
      <b-nav-item
        v-for="item in items"
        :key="item.id"
        :to=" { name : item.routeName } "
        exact
        exact-active-class="sub-tab-nav"
        :link-classes="['sub-tab-nav', 'px-3', bgTabsNavs]"
      >
        {{ item.description.toUpperCase() }}
      </b-nav-item>
    </b-nav>
    <b-card
      no-body
      class="border-top-info border-3 border-table-radius px-0"
    >      <router-view :key="$route.name" />
    </b-card>
  </div>
</template>
<script>

import data from './data/db.json'

export default {
  data() {
    return {
      items: data,
      tab: 1,
    }
  },

}
</script>
